import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "The RTSP Stream in VLC Player is not running smoothly",
  "path": "/Frequently_Asked_Question/RTSP_Stream_VLC_Debugging/",
  "dateChanged": "2021-01-29",
  "author": "Mike Polinowski",
  "excerpt": " When high definition videos of large sizes are opened in VLC Media Player—the HD video doesn’t play perfectly at all times. The HD video freezes and skips a few frames while the audio plays perfectly.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - The RTSP Stream in VLC Player is not running smoothly' dateChanged='2021-01-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='When high definition videos of large sizes are opened in VLC Media Player—the HD video doesn’t play perfectly at all times. The HD video freezes and skips a few frames while the audio plays perfectly.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/RTSP_Stream_VLC_Debugging/' locationFR='/fr/Frequently_Asked_Question/RTSP_Stream_VLC_Debugging/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "the-rtsp-stream-in-vlc-player-does-not-run-smoothly",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-rtsp-stream-in-vlc-player-does-not-run-smoothly",
        "aria-label": "the rtsp stream in vlc player does not run smoothly permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The RTSP stream in VLC Player does not run smoothly.`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: Is there a problem with the cameras when streaming in full resolution? Camera is connected via dLAN. If I call up the live video in the WebUI (MJPEG), the image and the superimposed time run smoothly. If I use VLC and channel 11](/Indoor_Cameras/IN-8015_HD/Video_Streaming/), the picture often freezes and also stops permanently. Even on channel 12 I had a freeze frame in the VLC yesterday.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Please first make sure that streaming via TCP`}<strong parentName="p">{` is active for `}</strong>{`RTSP. To do this, open the properties (`}<strong parentName="p">{`Preferences`}</strong>{`) under Tools (`}<strong parentName="p">{`Tools`}</strong>{`). Then go to the network section under Input / Codecs and make sure that streaming of the video via TCP is active:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "797px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b8bbeeb201abb8855d8695482fefac2/43fbc/RTSP_over_TCP_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNUlEQVQ4y3WSTW/bMAyG/f8vO+yP7LrtMGDDhmYfbYG6jW1JlmzZFiV/iLLTJE1SoJCVBBmKPAeZAvySIl9Gq0Hb3rZdt95sdruX/eGw2+8Pnv3zy2G9f92+7A6vr7sLttvtarXabDaRAk6LfxO69PfX1fqZ3HwxdcWX9zkli0R++rZIq76Mfw3o7Ak8EeV5cnP306gmvf0B2vB40Rv1cH+3TNLbJ/L5+4JKQx7+XGoQMVwjnvOccUIpYTyO4xoMohun1TiOIXD+s8J3WGsjAKCMgYK6rtq2RfQpy7KQUhpjAEBrrcN5AQD0fR8ZY9I0LcsSAIwxIWvTNHVdK6XMFbTWXgwAWUYoIWmaUspCa0oprXVRFOdSQfC+siZZRghJkkSIIjQTxzEhVEpZzAghCCHyAiGEMcaLsywTQuQsV0o55xCRMlZKWZ+QcxzyBoYZ/+w0zaSsENG5o5lu9LgT8208zzkUOIrrutbGdF139mAYBnudvu+7rvNirTWjVHCR51wp1bZtWIDLUpc456SUjDE/MK19z5QxSqkQQilAxKqSpSz1dY7TBgDGcq110zTh5dbaME847QYoBf+jlOq6LgIFSZJQSvu+D5NAxKIoAKBt22tLAgBebLQhGaGMVVV13v6yLDnnWms3TdYY++GjflxKpYqimFcYj1YFe9D6SUzTFIqfnQyeWi5s1w1z7OY/goURo+zp8amqKsZYslz6arP+3IJnvUbnRoftgH+T4pGrnAsp5Rv4G9ZV72g1GAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b8bbeeb201abb8855d8695482fefac2/e4706/RTSP_over_TCP_01.avif 230w", "/en/static/4b8bbeeb201abb8855d8695482fefac2/d1af7/RTSP_over_TCP_01.avif 460w", "/en/static/4b8bbeeb201abb8855d8695482fefac2/48116/RTSP_over_TCP_01.avif 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b8bbeeb201abb8855d8695482fefac2/a0b58/RTSP_over_TCP_01.webp 230w", "/en/static/4b8bbeeb201abb8855d8695482fefac2/bc10c/RTSP_over_TCP_01.webp 460w", "/en/static/4b8bbeeb201abb8855d8695482fefac2/9eee1/RTSP_over_TCP_01.webp 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b8bbeeb201abb8855d8695482fefac2/81c8e/RTSP_over_TCP_01.png 230w", "/en/static/4b8bbeeb201abb8855d8695482fefac2/08a84/RTSP_over_TCP_01.png 460w", "/en/static/4b8bbeeb201abb8855d8695482fefac2/43fbc/RTSP_over_TCP_01.png 797w"],
              "sizes": "(max-width: 797px) 100vw, 797px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b8bbeeb201abb8855d8695482fefac2/43fbc/RTSP_over_TCP_01.png",
              "alt": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "title": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Q`}</strong>{`: When opening large HD videos in VLC Media Player, the HD video does not always play perfectly. The HD video may freeze and some frames are skipped while the audio plays perfectly. The video might hang for a few seconds and start playing again at the same point.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: `}<strong parentName="p">{`Increase cache values`}</strong>{` - Even if you play an offline video, VLC needs to cache it. It loads a certain part of the video from the hard disk into your computer's main memory or RAM. From there, the player accesses it and plays it. It happens all the time while a video is playing. For HD video, it helps to increase the amount of video in the buffer to ensure that there is always enough video to play the video smoothly.`}</p>
    <p>{`This way you increase the buffering of files:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/45139424797dc38e34650e30923d9d85/e9beb/VLC_Cache_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABmklEQVQoz6XNvUsCcRzH8a+X2tQQtEdQg5b5QEP3u+OEFFvyIYKi/oemkGqKSJf6DxraWkLblBoiepAG74wgSsO786f5kN7Z1WlCU1ARlvYAvfiu788XJmIh++GO4zjs4CJjbMTJRWxHu6aDHXQaIk9CKBZGsTB5GnImoq6L/fc733OxERcXBa/b7Zlwez0en9sz5Zt02O2bW9v5YpVPpvI4+6SqSrX63Ggs+f1TPt/czMzs9PTHQTOtVgsAG6t+pVJi49zV5VWxUCzkC6qqGg0GACAI4lNANOnU6wFgfW25UVNT1ymcwZIkZTGWZdlmswGATqfraNLmcyAQLJclNs5inJPlKsbZckWymM1tPrfGwWCg9nCfTiXzuWzlrqTIUqP+aLFa/hSvzC9kEumz6MnFUSLNJW/iSZFLD/cP/BJrNBoA6O3uGewdGrcaFydH/F6rw2oy9Zm7tLo2AdHibeLrLtEG/AvD2GmapigKIUS9QgiN/ogkSZqiGYaB3O0tz/OCIIiiKAgCz/OiKNa+V6/VZbmcKWUURXkBlrnlIp/lXVAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45139424797dc38e34650e30923d9d85/e4706/VLC_Cache_01.avif 230w", "/en/static/45139424797dc38e34650e30923d9d85/d1af7/VLC_Cache_01.avif 460w", "/en/static/45139424797dc38e34650e30923d9d85/b6582/VLC_Cache_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/45139424797dc38e34650e30923d9d85/a0b58/VLC_Cache_01.webp 230w", "/en/static/45139424797dc38e34650e30923d9d85/bc10c/VLC_Cache_01.webp 460w", "/en/static/45139424797dc38e34650e30923d9d85/87ca7/VLC_Cache_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45139424797dc38e34650e30923d9d85/81c8e/VLC_Cache_01.png 230w", "/en/static/45139424797dc38e34650e30923d9d85/08a84/VLC_Cache_01.png 460w", "/en/static/45139424797dc38e34650e30923d9d85/e9beb/VLC_Cache_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/45139424797dc38e34650e30923d9d85/e9beb/VLC_Cache_01.png",
              "alt": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "title": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the player settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e9beb/VLC_Cache_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAChklEQVQ4y22SyW4UMRCG+9XIgQNPgJA4cOSKEAdeAMETIN6B7QbigAKRQAJEIEPvi7f20rbLdjuaTGYuqLsTmAi+Q6ktddVv//Un4JwJaz/G9dnZ+fn5dreby3a73Z5vt7vpuJm+99hsNmczyWFavfi+qolqv70L8TQ7fG04//XlI8GEV9/b9JuRrDk+8iE6AHeV5MPxyYujz3nVFceffAi0OLZGr37+wAjjpijTn1LKOl/5EPw/JF3TlllRlmVRNRbAhQgAIQTnwPkQxuic82F0/yMZBo0QBoAYx2mac3OZGo3WPWPDMCgp1VWEEH3fJ5yLNMsIZc75/anee621EGKY0VovdUEpRSlNAACs8WC9cwDAGKOUaq0X/TA/VQhBKHXgxnEMMwAwNYcxhvXOr3fhdN00TTE9vyyKQgjp5nFCCM65EEJKudzIe3/R/Pzlq2sHBzeuH9x78LDvWZplVVUhhJefnHMY46qs2rYtq0oNw3KXi+Y3b9/euX3z/t1bj5886vu+LMu27ay1f/ZBKe26jnMupRyG4YpyjHEKzXZ3tlm3bZsXRZ4XSqlwsViXpmlRlIQQSinn/IoyYyzLsjzLCSGXDX9tN8ZYaydLrVl8hjln1tqpue/71cnq5GSVZxkhFGOCEBJCzDlxfc/pBGOMKaUAwHv/122lVF3XTTPFK+xlMIQwBbOuMcZohjEmpVxs55wTQhIhRDWDELLW7ocEAIwxcIm11hizJGQZkQjOK4TyskrTbNnEkg1j9GLbPjFGYwxlrJ+dS3pjmqfPqsMPNcb0wrNJdtEZ/+E0xtMYx3EEgKRBqHv/UbWow7iua631pbjft8B5PwYvNHzNSY4F6joh5G+0eMxaBn8/ZgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e4706/VLC_Cache_02.avif 230w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/d1af7/VLC_Cache_02.avif 460w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/b6582/VLC_Cache_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3db0063e458518ba91b7a73cb0e3d1f6/a0b58/VLC_Cache_02.webp 230w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/bc10c/VLC_Cache_02.webp 460w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/87ca7/VLC_Cache_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3db0063e458518ba91b7a73cb0e3d1f6/81c8e/VLC_Cache_02.png 230w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/08a84/VLC_Cache_02.png 460w", "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e9beb/VLC_Cache_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3db0063e458518ba91b7a73cb0e3d1f6/e9beb/VLC_Cache_02.png",
              "alt": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "title": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Activate the display of all settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c57baf24dc36bdff8a6727381e8c19ba/e9beb/VLC_Cache_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADQklEQVQ4yz2TS28bVRTH5+vxESgbBAZWLNln302RXClxWpuSlKqBtApuEhxREju2aztS26SpizJ3JvO68/Lc57wf9qAZk/50dFf36H8e/yN8eX/jh5c7jWePGnudxv6Tz/H1Xvve7uZXu5v3djcbL379ro7Giyff7v/yzV77++5vX/z0ozDo94s8D4MgS9O8jixJlnnuM34LZKgbt7LMCPUZ9ykLfT9LkigMy7J8uvtU6PV6qqJIQOKcR1EUhmEURYwx27bn8/n795eaphuGoRuGruumafq+zxgriuLxo8fCbDYLwxAjXBRFXrNcLrnvm9CEEAIROI4TBAGvoZRijBFCSZJ0Oh1hOp1xxi3LStM0y7I0TYs855ybpimK4OrqAwASY4zWEEI4pYzSoiiq5MH5OSHYMq0wDP9PLgrOOTQtTdNFABDG9A5CKY1jEgZ5ltXKk0kYBAt3sZ5ZmiRFlvHAN0Vw2z1UX58yn1eKGBNKievit+/cDx+DOGm3O8L04iKMY82AfhQVZZktl8uy9OMYGlC/uhbfvoOmiTGihFDOqQFxq23/caA4eKu1LZztv5Rf/XX9+75y3LNP+9Y/Z/ZpXzvuQQBk3bj+OFcUldR9Ut+nqoYfbnkHrzQIW1tbwvj0zBPBv28mcP4pMC2uGwE0PUmGqqbrOhCBoiiWZeG7zrFpLiA0LbvValVlZ0XhYpyvVkVZ5qvVqiyDODYtC4hgPv8ky7JtO+tkxtjC80QAoAG3t7eF6XRKMZGBVA2snnaaplEUeZ6naZquG67rIoTWSyaUYoRcx/E8r1KeTCZRFEHDCIIgz/OsJk1TQikA4PLyEoCqcs/z1ntmjKmq6rqLZrMpDAbnCFUicZxkdyRJgglRVVWWby3b9jwPY8zufKIoim3bVfJsdpEmiaqoruN+dmitTG5uxFpZWiwWlmUTQhBGmFT29Dzvwc8PhPF4TDCRKieh2iMV654lSbqp0TXdsiyE8aIG1z8r5fH4TZpmQVBdWVmWq1UVRbEMwwgh5FRUA1vfwxrHcVar1c7OjvCw2ex2uycnJ0dHR4eHh/1+fzwej0aj9btmOByeD4ej0fD12eD5nycHx38P+v2NjY3/ALSaqNH82gHyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c57baf24dc36bdff8a6727381e8c19ba/e4706/VLC_Cache_03.avif 230w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/d1af7/VLC_Cache_03.avif 460w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/b6582/VLC_Cache_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c57baf24dc36bdff8a6727381e8c19ba/a0b58/VLC_Cache_03.webp 230w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/bc10c/VLC_Cache_03.webp 460w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/87ca7/VLC_Cache_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c57baf24dc36bdff8a6727381e8c19ba/81c8e/VLC_Cache_03.png 230w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/08a84/VLC_Cache_03.png 460w", "/en/static/c57baf24dc36bdff8a6727381e8c19ba/e9beb/VLC_Cache_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c57baf24dc36bdff8a6727381e8c19ba/e9beb/VLC_Cache_03.png",
              "alt": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "title": "Der RTSP-Stream im VLC Player läuft nicht flüssig",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to Input / Codecs, Advanced and increase the File & Disk Cache value to 500 - 1000ms (default is 300ms). Please note that a value that is too high can also have negative effects.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      